import { logInRequest } from 'api/AuthApi';
import { axiosInstance } from 'api/axiosConfig';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { changePwProcess, changePwRequest, signInFail, signInRequest, signInSuccess } from '../slices/authSlice';

const URI = `${process.env.REACT_APP_PLM_API}/user`;
const AUTH_URI = `${process.env.REACT_APP_PLM_API}/auth`;

const selectIDorEmail = (account) => {
  const obj = {};
  if (account?.includes('@')) {
    obj.emailAddr = account;
  } else {
    obj.userId = account;
  }
  return obj;
};

function* handleSignIn({ payload }) {
  try {
    // const account = selectIDorEmail(payload.account);
    const params = { password: payload.password, userId: payload.account };
    const response = yield call(logInRequest, params);
    const status = response?.status;
    const data = response?.data;
    if (!data) throw Error;
    if (status === 'SUCCESS') {
      yield put(signInSuccess({ ...data }));
    } else {
      yield put(signInFail({ userId: '' }));
    }
  } catch (error) {
    yield put(signInFail({ userId: '' }));
  }
}

function* handleChangePw({ payload }) {
  try {
    const account = selectIDorEmail(payload.account);
    const params = { pw: payload.password, chngPw: payload.newpassword, ...account };

    /* 이메일로 USER ID 조회 후 비밀번호 변경API로 PASS */
    const userResponse = yield call(axiosInstance.get, `${URI}/info/${params?.emailAddr}`, params);
    const userData = userResponse?.data;
    if (!userData) throw Error;

    const response = yield call(axiosInstance.patch, `${URI}/updatepass`, { ...params, userId: userData?.userId });
    const status = response?.status;
    const data = response?.data;
    /* 일단 모든 response가 200이기때문에 메시치 표현없이 바로 로그인화면으로 redirect */
    yield put(changePwProcess(null));
  } catch (error) {
    console.error(error);
    // 실패하면 비밀번호 변경 화면 유지
    // yield put(changePwFail({ changePassword: true }));
  }
}

/* function* handleResetPw({ payload }) {
  try {
    const account = selectIDorEmail(payload.account);
    const params = { ...account };

    const apiResponse = yield call(axiosInstance.patch, `${URI}/initpass`, params);
    const status = apiResponse?.response.status;
    // const data = apiResponse?.response.data;
    if (status === 200 || status === 201) {
      // 성공하면 로그인 화면으로 이동
      yield put(resetPwSuccess({ callReset: false }));
    } else {
      // 실패하면 비밀번호 초기화 화면 유지
      yield put(resetPwFail({ callReset: true }));
    }
  } catch (error) {
    // 실패하면 비밀번호 초기화 화면 유지
    yield put(resetPwFail({ callReset: true }));
  }
}
 */

function* authSaga() {
  yield all([
    takeEvery(signInRequest.type, handleSignIn),
    takeEvery(changePwRequest.type, handleChangePw),
    // takeEvery(resetPw.type, handleResetPw),
  ]);
}

export default authSaga;
