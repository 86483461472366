import { persistStore } from 'index';
import { store } from 'store';
import { signOut } from 'store/slices/authSlice';
import { resetTabs } from 'store/slices/tabSlice';
import { axiosInstance } from './axiosConfig';
const URI = '/auth';

export const logInRequest = async (params) => {
  const response = await axiosInstance.post(`${URI}/login`, params);
  return response.data;
};

export const signOutRequest = async () => {
  const response = await axiosInstance.post(`${URI}/logout`);
  return response.data;
};

export const reissuance = async () => {
  try {
    const response = await axiosInstance.post('/auth/reissuance');
    return response;
  } catch (err) {
    await signOutRequest()
      .catch(() => {
        // 에러 전파 방지
      })
      .finally(() => {
        alert('로그아웃되었습니다.');
        store.dispatch(signOut());
        store.dispatch(resetTabs());
      });
    await persistStore.purge();
    return err;
  }
};
