import { Button, Card, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { passwordValid, requiredValid } from 'service/formValid';
import { signInRequest } from 'store/slices/authSlice';
const { Password } = Input;

function Login(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  const onFinish = (e) => {
    const payload = form.getFieldsValue();
    dispatch(signInRequest(payload));
  };

  /* const showMessage = useCallback(() => {
    if (auth?.userId) {
      message.success(`${auth?.userNm}님 환영합니다.`);
    } else if (auth?.userId === '') {
      message.error('로그인 실패했습니다.');
    }
  });

  useEffect(() => {
    showMessage();
  }, [auth]); */

  return (
    <>
      <Row align="middle" justify="space-around" style={{ height: '100vh' }}>
        <Col span={6}>
          <Card style={{ padding: 24 }}>
            <h2 style={{ marginBottom: 40 }}>로그인</h2>

            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={() => onFinish()}
              autoComplete="off"
            >
              <Form.Item
                label="E-mail"
                name="account"
                rules={[
                  // eMailTypeValid(),
                  requiredValid('E-mail'),
                ]}
              >
                <Input onChange={(e) => form.setFieldValue('account', e.target.value)} />
              </Form.Item>

              <Form.Item
                label="비밀번호"
                name="password"
                style={{ marginBottom: 12 }}
                rules={[requiredValid('비밀번호'), { validator: passwordValid }]}
              >
                <Password onChange={(e) => form.setFieldValue('password', e.target.value)} />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox text="Remember Me" onClick={(e) => console.log(e)} />
          </Form.Item> */}

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                  LOG IN
                </Button>
              </Form.Item>
            </Form>
            {/* <div>
          회원가입이 필요하신가요?
          <span className="icon icon-alipay" />
          <span className="icon icon-taobao" />
          <Button
            type="link"
            style={{ float: 'right' }}
            text="회원가입"
            // onClick={() => onRegister()}
            disabled={true}
          />
        </div> */}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Login;
