import _ from 'lodash';

export const projectQueryKey = {
  all() {
    return ['project'];
  },
  search(sp) {
    let key = this.all();
    ['clientCd', 'pjtCd', 'pjtSttdCd'].forEach((k) => {
      if (Array.isArray(sp?.[k]) && sp[k].length > 0) {
        key = key.concat(_.sortBy(sp[k]));
      }
    });
    return key;
  },
  detail(pId) {
    return this.all()
      .concat(['detail', pId])
      .filter((i) => !!i);
  },
  members(pId) {
    return this.all()
      .concat(['members', pId])
      .filter((i) => !!i);
  },
};

export const commonCodeQueryKey = {
  all() {
    return ['commoncode'];
  },
  detail(cId) {
    return this.all()
      .concat('detail', cId)
      .filter((i) => !!i);
  },
  search(sp) {
    return this.all().concat(...Object.values(sp));
  },
};

export const saramQueryKey = {
  all() {
    return ['saram'];
  },
  search(sp) {
    let key = ['saram', sp.intrPrtyNm, sp.page ?? 0];
    if (sp.startDate) key.push(sp.startDate);
    if (sp.endDate) key.push(sp.endDate);
    ['intrPrtyCd', 'jobTyCd', 'techGrdTyCd', 'techCd', 'admsectLocId'].forEach((k) => {
      if (Array.isArray(sp?.[k]) && sp[k].length > 0) {
        key = key.concat(_.sortBy(sp[k]));
      }
    });
    return key;
  },
  detail(id) {
    return this.all().concat('detail', id);
  },
  relation(id) {
    return this.detail(id).concat('relation');
  },
  project(id) {
    return this.detail(id).concat('project');
  },
  memo(id) {
    return this.detail(id).concat('memo');
  },
  history(id) {
    return this.detail(id).concat('history');
  },
};

export const admsectQueryKey = {
  all() {
    return ['admsect'];
  },
};
