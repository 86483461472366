import { BellOutlined } from '@ant-design/icons';
import { Avatar, Col, Layout, Menu, Row, Space, Select, Popover, Dropdown } from 'antd';
import useTabs from 'hooks/useTabs';
import UserPopoverContent from 'templates/popover/UserPopoverContent';
import { useSelector } from 'react-redux';

const { Header, Footer } = Layout;

export default function GnbMenu(props) {
  const { gnbMenus, selectGnb, languageOptions, onChangeLanguage } = props;
  const { user } = useSelector((state) => ({ user: state?.auth }));
  const { handleOpenHomeTab } = useTabs();
  const avatarText = user?.userIdInf?.[0]?.toUpperCase();

  return (
    <Layout>
      <Header className="headerWrapper">
        <Row>
          <Col sm={6} xl={4} className="logo" style={{ cursor: 'pointer' }} onClick={() => handleOpenHomeTab()}>
            <span>PLM</span>
            <span>Platform</span>
          </Col>
          <Col sm={12} xl={16}>
            <Menu
              style={{ justifyContent: 'center' }}
              theme="dark"
              mode="horizontal"
              items={gnbMenus}
              onClick={(e) => selectGnb(e.key)}
            />
          </Col>
          <Col sm={6} xl={4}>
            <Space size="middle">
              <Select
                className="headerSelect"
                defaultValue="korean"
                onChange={onChangeLanguage}
                style={{ width: 100, height: 34 }}
                options={languageOptions}
              />
              <BellOutlined />
              <Popover
                trigger="click"
                content={
                  <UserPopoverContent
                    {...user}
                    avatarText={
                      <Avatar size={36} style={{ backgroundColor: `#b3cf0a` }}>
                        {avatarText}
                      </Avatar>
                    }
                  />
                }
              >
                <Avatar size={24} style={{ cursor: 'pointer' }}>
                  {avatarText}
                </Avatar>
              </Popover>
            </Space>
          </Col>
        </Row>
      </Header>
      {props.children}
      <Footer style={{ textAlign: 'center' }}></Footer>
    </Layout>
  );
}
