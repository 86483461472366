import { Col, Row, Breadcrumb as _Breadcrumb } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRoleCmpRequest } from 'store/slices/menuSlice';

function Breadcrumb(props) {
  const { prntsMenuNm, menuNm } = props;

  return (
    <_Breadcrumb
      items={[
        {
          title: prntsMenuNm,
        },
        {
          title: menuNm,
        },
      ]}
    />
  );
}

export default function Title(props) {
  const { menuId, menuNm, pageNm, role = [] } = props;
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getRoleCmpRequest({ menuId, role }));
  // }, [menuId, role]);

  return (
    <>
      <Row align="middle" className="titleWrapper">
        <Col span={12}>
          <div className="menuTitle">
            <span className="circle"></span>
            {pageNm ? <h3>{pageNm}</h3> : <h3>{menuNm}</h3>}
          </div>
        </Col>
        <Col span={12}>
          <Breadcrumb {...props} />
        </Col>
      </Row>
    </>
  );
}

export function ModalTitle(props) {
  const { title } = props;
  return (
    <Row align="middle" className="titleWrapper">
      <Col span={12}>
        <div className="menuTitle">
          <span className="circle"></span>
          <h3>{title}</h3>
        </div>
      </Col>
    </Row>
  );
}

export function ModalTileButton(props) {
  const { title, children } = props;
  return (
    <Row align="middle" className="titleWrapper">
      <Col span={20}>
        <div className="menuTitle">
          <span className="circle"></span>
          <h3>{title}</h3>
        </div>
      </Col>
      <Col span={4}>{children}</Col>
    </Row>
  );
}
