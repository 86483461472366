import useAdminSector from 'hooks/useAdminSector';
import * as pages from 'pages';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from './router';

// eslint-disable-next-line react/function-component-definition
const AppRouter = () => {
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));
  const navigate = useNavigate();
  const location = useLocation();
  useAdminSector();

  useEffect(() => {
    if ([ROUTES.SARAM, ROUTES.PROJECT, ROUTES.SERVICE].includes(location.pathname)) {
      // 임시페이지 auth없이 접근가능하도록 처리
      return;
    }
    if (!auth) {
      navigate(ROUTES.LOGIN);
    }
    if (auth?.changePassword === true) {
      navigate(ROUTES.CHANGE_PW);
    } else if (auth?.changePassword === false) {
      navigate(ROUTES.PLM);
    }

    if (auth?.userId) {
      if (location.pathname === '/' || location.pathname === ROUTES.LOGIN) {
        navigate(ROUTES.PLM);
      }
    }
  }, [auth, location.pathname]);

  return (
    <Routes>
      <Route element={<pages.Login />} path={ROUTES.LOGIN} />
      <Route element={<pages.ChangePw />} path={ROUTES.CHANGE_PW} />
      <Route element={<pages.PLM />} path={ROUTES.PLM} />
      <Route path="*" element={<Navigate replace to={ROUTES.LOGIN} />} />
    </Routes>
  );
};

export default AppRouter;
