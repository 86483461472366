import { useMutation, useQuery } from '@tanstack/react-query';

/**
 *
 * @param {{
 * key: string[];
 * getFunction: (req: any) => Promise<any>;
 * enabled: boolean;
 * initialData: any;
 * staleTime: any;
 * gcTime: any;
 * }} props
 * @returns
 */
export const useGetItems = (props) => {
  const { key, getFunction, enabled = true, initialData, select, staleTime = 0, gcTime = 5 * 60 * 1000 } = props;
  const query = useQuery({
    queryKey: key,
    queryFn: getFunction,
    enabled: !!enabled,
    initialData: initialData ?? undefined,
    select,
    staleTime,
    gcTime,
  });
  return query;
};

/**
 * @param {{
 * saveFunction: (req: any) => Promise<any>;
 * }} props
 * @returns
 */
export const useSaveItems = (props) => {
  const { saveFunction } = props;
  const mutation = useMutation({
    mutationFn: saveFunction,
  });
  return mutation;
};

/**
 * @param {{
 * updateFunction: (req: any) => Promise<any>;
 * }} props
 * @returns
 */
export const useUpdateItems = (props) => {
  const { updateFunction } = props;
  const mutation = useMutation({
    mutationFn: updateFunction,
  });
  return mutation;
};

/**
 * @param {{
 * deleteFunction: (req: any) => Promise<any>;
 * }} props
 * @returns
 */
export const useDeleteItems = (props) => {
  const { deleteFunction } = props;
  const mutation = useMutation({
    mutationFn: deleteFunction,
  });
  return mutation;
};
