import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setClaimFeildDetail(state, action) {
      state.claimFeildDetail = action.payload;
    },
    setClaimProcessDetail(state, action) {
      state.claimProcessDetail = action.payload;
    },
  },
});

export const { setLoading } = pageSlice.actions;

export default pageSlice.reducer;
