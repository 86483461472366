/* AUTH */
export const LOGIN = '/login';
export const CHANGE_PW = '/chnagepw';
export const RESET_PW = '/resetpw';
export const SIGN_UP = '/signup';
/* HOME */
export const PLM = '/plm';

/* TEMP (퍼블리싱) */
export const SARAM = '/saram';
export const PROJECT = '/project';
export const SERVICE = '/service';
