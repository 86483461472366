import { getAdmsect } from 'api/AdminSectorApi';
import { useEffect, useState } from 'react';
import { admsectQueryKey } from 'service/queryKeyFactory';
import { useGetItems } from './useFetchItems';

export default function useAdminSector() {
  const [admsectOption, setAdmsectOption] = useState([]);
  const [keyMapping, setKeyMapping] = useState({});
  const { data: queryData } = useGetItems({
    key: admsectQueryKey.all(),
    getFunction: getAdmsect,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!queryData) return;
    const newKeyMapping = {};
    const mapping = {};
    const newData = [];
    /**
     * 몇 depth인지 모른다.
     * 만들어야 할 구조:
     * { label, value, children[] }[]
     * 처음 한 번 순회하면서, mapping admsectLocId를 key로 객체를 맵핑한다.
     * 다시 순회하면서, 부모가 없으면 newData에 추가하고, 있으면 mapping 객체의 children에 추가한다.
     */

    queryData.forEach((item) => {
      newKeyMapping[item.admsectLocId] = item;
      mapping[item.admsectLocId] = {
        label: item.admsectLocNm,
        value: item.admsectLocId,
        children: [],
      };
    });

    queryData.forEach((item) => {
      const node = mapping[item.admsectLocId];
      if (item.upperAdmsectLocId === null || item.upperAdmsectLocId === '') {
        // 부모가 없는 경우
        newData.push(node);
      } else {
        // 부모가 있는 경우
        mapping[item.upperAdmsectLocId].children.push(node);
      }
    });
    setAdmsectOption(newData);
    setKeyMapping(newKeyMapping);
  }, [queryData]);

  return { admsectOption, keyMapping };
}
