import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenusRequest, menuStoreItems } from 'store/slices/menuSlice';
import GnbMenu from 'templates/pagelayout/GnbMenu';
import Tabs from 'components/atoms/Tabs';
import { Modal, Alert } from 'components/atoms/Modal';
import NotOpenPage from 'components/molecules/NotOpenPage';
import useTabs from 'hooks/useTabs';
import useUser from 'hooks/useUser';
import _ from 'lodash';
import { Popover } from 'antd';

function DynamicComponentLoader(props) {
  const { menuId, path } = props;
  const [PageComponent, setPageComponent] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        let Dynamic;
        if (menuId) {
          Dynamic = React.lazy(() =>
            import(`../../pages${path}`).catch((error) => {
              return { default: () => <NotOpenPage {...props} /> };
            }),
          );
          setPageComponent(() => Dynamic);
          setError(null);
        }
      } catch (error) {
        console.error('Error loading component:', error);
        setPageComponent(null);
        setError(error.message);
      }
    };

    loadComponent();
  }, [path, menuId]);

  if (error) {
    return <div>Error loading component: {error}</div>;
  }

  return (
    PageComponent && (
      <Suspense fallback={<div></div>}>
        <PageComponent {...props} />
      </Suspense>
    )
  );
}

function Layout2(props) {
  const dispatch = useDispatch();
  useUser();

  const menus = useSelector(menuStoreItems);
  const { user } = useSelector((state) => ({
    user: state?.auth,
  }));
  const { modal, alert } = useSelector((state) => ({
    modal: state?.modal?.items,
    alert: state?.modal?.alertItems,
  }));
  // const { handleOpenTabs, handleCloseTabs, handleSelectTabs, handleOpenHomeTab } = useTabs();
  const [selectedGnb, selectGnb] = useState('');
  const [gnbMenus, setGnbMenus] = useState([]);
  const [pageParams, setPageParams] = useState({ menuId: '', path: '' });

  const languageOptions = [
    { value: 'korean', label: '한국어' },
    { value: 'english', label: 'English' },
  ];

  const onChangeLanguage = (lang) => {
    console.log(`lang ${lang}`);
  };

  /* 페이지 리스트 뽑아내기 */
  const flatMenus = (nestedMenus) => {
    const result = [];
    const flatten = (items) => {
      items.forEach((item) => {
        result.push(item);
        if (item.children) {
          flatten(item.children);
        }
      });
    };
    flatten(nestedMenus);
    return result;
  };

  /* 메뉴 선택하여 페이지 렌더링 데이터 SET */
  useEffect(() => {
    const handleRenderPage = () => {
      const flatedMenus = flatMenus(gnbMenus);
      const componentPage = flatedMenus.filter((item) => item.menuId === selectedGnb);
      if (!componentPage.length) return;

      setPageParams(componentPage[0]);
    };

    handleRenderPage();
  }, [gnbMenus, selectedGnb]);

  /* GNB 메뉴(상단 메뉴) 렌더링 데이터 SET */
  useEffect(() => {
    setGnbMenus(
      menus.map((item) => ({
        ...item,
        key: item?.menuId,
        label: item.menuNm,
      })),
    );
  }, [menus]);

  /* 사용자별 메뉴 목록 조회하기 */
  useEffect(() => {
    dispatch(getMenusRequest(user));
  }, [user?.userId]);

  return (
    <div>
      <GnbMenu
        gnbMenus={gnbMenus}
        selectGnb={selectGnb}
        languageOptions={languageOptions}
        onChangeLanguage={onChangeLanguage}
      >
        <DynamicComponentLoader {...pageParams} />
      </GnbMenu>

      <Modal {...modal} />
      <Alert {...alert} />
    </div>
  );
}

export default Layout2;
